import { PrimeIcons } from "primereact/api";
import { BaseFrontendRoutes } from "./BaseFrontendRoutes";
import {
  HOME_ROUTE_PATH,
  LANDING_ROUTE_PATH,
  LOOKUPSROUTE_PATH,
  MEMBERS_ROUTE_PATH,
  MEMBER_DETAILS_ROUTE_PATH,
  GROUPS_ROUTE_PATH,
  USERS_ROUTE_PATH,
  ADVERTS_ROUTE_PATH,
  SOS_CALLS_ROUTE_PATH,
  PRODUCT_CATEGORIES_VIEW_ROUTE_PATH,
  PRODUCTS_VIEW_ROUTE_PATH,
  ORDERS_VIEW_ROUTE_PATH,
  PRODUCT_FORM_ROUTE_PATH,
  AGGREGATOR_TRANSACTIONS_VIEW_ROUTE_PATH,
  WALLET_TRANSACTIONS_VIEW_ROUTE_PATH,
  CURRENCIES_ROUTE_PATH,
  PUSH_NOTIFICATIONS_ROUTE_PATH,
  TRANSLATION_LOGS_ROUTE_PATH,
  SETTINGS_ROUTE_PATH,
} from "./PageRoutes";
import Dashboard from "../../../pages/Dashboard";
import MembersView from "../../../pages/MembersView";
import UsersView from "../../../pages/UsersView";
import GroupsView from "../../../pages/GroupsView";
import LookupsView from "../../../pages/LookupsView";
import MemberDetailsView from "../../../pages/MemberDetailsView";
import Login from "../../../pages/Login";
import AdvertsView from "../../../pages/AdvertsView";
import SOSCallsView from "../../../pages/SOSCallsView";
import ProductCategorysView from "../../../pages/ProductCategoriesView";
import ProductsView from "../../../pages/ProductsView";
import OrdersView from "../../../pages/OrdersView";
import ProductForm from "../../../pages/ProductForm";
import AggregatorTransactions from "../../../pages/AggregatorTransactions";
import WalletTransactions from "../../../pages/WalletTransactions";
import CurrenciesView from "../../../pages/CurrenciesView";
import PushNotificationsView from "../../../pages/PushNotificationsView";
import TranslationLogsView from "../../../pages/TranslationLogsView";
import SettingsForm from "../../../pages/SettingsForm";
export class NormalUserRoutes extends BaseFrontendRoutes {
  /**
   * This sets up the back office routes with
   * @param userDetails,history
   */
  constructor(userDetails: {}) {
    super(userDetails);
  }

  getNavigationMenu(): any[] {
    let navigationMenu = [];
    let menuItems = [];
    menuItems.push({
      label: "Dashboard",
      icon: PrimeIcons.HOME,
      to: HOME_ROUTE_PATH,
      exact: true,
    });
    menuItems.push({
      label: "App Users",
      icon: PrimeIcons.USERS,
      to: MEMBERS_ROUTE_PATH,
      exact: true,
    });
    menuItems.push({
      label: "Groups",
      icon: PrimeIcons.BARS,
      to: GROUPS_ROUTE_PATH,
      exact: true,
    });
    menuItems.push({
      label: "SOS Calls",
      icon: PrimeIcons.BELL,
      to: SOS_CALLS_ROUTE_PATH,
      exact: true,
    });
    menuItems.push({
      label: "Translator",
      icon: PrimeIcons.VOLUME_UP,
      to: TRANSLATION_LOGS_ROUTE_PATH,
      exact: true,
    });
    menuItems.push({
      label: "Shop",
      icon: PrimeIcons.SHOPPING_BAG,
      items: [
        {
          label: "Product Categories",
          icon: PrimeIcons.CHART_PIE,
          to: PRODUCT_CATEGORIES_VIEW_ROUTE_PATH,
          exact: true,
        },
        {
          label: "Products",
          icon: PrimeIcons.TAGS,
          to: PRODUCTS_VIEW_ROUTE_PATH,
          exact: true,
        },
        {
          label: "Order Mgt",
          icon: PrimeIcons.CALENDAR_TIMES,
          to: ORDERS_VIEW_ROUTE_PATH,
          exact: true,
        },
      ],
    });
    menuItems.push({
      label: "Wallet",
      icon: PrimeIcons.MONEY_BILL,
      items: [
        {
          label: "Aggregator Txns",
          icon: PrimeIcons.CHART_PIE,
          to: AGGREGATOR_TRANSACTIONS_VIEW_ROUTE_PATH,
          exact: true,
        },
        {
          label: "Wallet Txns",
          icon: PrimeIcons.WALLET,
          to: WALLET_TRANSACTIONS_VIEW_ROUTE_PATH,
          exact: true,
        },
      ],
    });
    menuItems.push({
      label: "Adverts",
      icon: PrimeIcons.BRIEFCASE,
      to: ADVERTS_ROUTE_PATH,
      exact: true,
    });
    if (this.userDetails.isSuperAdmin === true) {
      menuItems.push({
        label: "Lookups",
        icon: PrimeIcons.BARS,
        to: LOOKUPSROUTE_PATH,
        exact: true,
      });
      menuItems.push({
        label: "Settings",
        icon: PrimeIcons.COG,
        to: SETTINGS_ROUTE_PATH,
        exact: true,
      });
    }
    if (this.userDetails.isSuperAdmin === true) {
      menuItems.push({
        label: "Push Notifications",
        icon: PrimeIcons.BELL,
        to: PUSH_NOTIFICATIONS_ROUTE_PATH,
        exact: true,
      });
    }
    if (this.userDetails.isSuperAdmin === true) {
      menuItems.push({
        label: "Currencies",
        icon: PrimeIcons.DOLLAR,
        to: CURRENCIES_ROUTE_PATH,
        exact: true,
      });
    }
    navigationMenu.push({ items: menuItems });
    return navigationMenu;
  }

  getAuthenticatedComponetRouters(): any[] {
    let routes = [];
    routes.push({
      path: LANDING_ROUTE_PATH,
      label: "Landing",
      component: Login,
      exact: true,
    });

    routes.push({
      path: HOME_ROUTE_PATH,
      label: "DashBoard",
      component: Dashboard,
      exact: true,
    });
    routes.push({
      path: MEMBERS_ROUTE_PATH,
      label: "Members",
      component: MembersView,
      exact: true,
    });
    routes.push({
      path: MEMBER_DETAILS_ROUTE_PATH,
      label: "Member Details",
      component: MemberDetailsView,
      exact: true,
    });
    routes.push({
      path: PRODUCT_CATEGORIES_VIEW_ROUTE_PATH,
      label: "Product Categories",
      component: ProductCategorysView,
      exact: true,
    });
    routes.push({
      path: ORDERS_VIEW_ROUTE_PATH,
      label: "Orders",
      component: OrdersView,
      exact: true,
    });
    routes.push({
      path: AGGREGATOR_TRANSACTIONS_VIEW_ROUTE_PATH,
      label: "AggTrxns",
      component: AggregatorTransactions,
      exact: true,
    });
    routes.push({
      path: WALLET_TRANSACTIONS_VIEW_ROUTE_PATH,
      label: "WalletTransactions",
      component: WalletTransactions,
      exact: true,
    });
    routes.push({
      path: PRODUCTS_VIEW_ROUTE_PATH,
      label: "Products",
      component: ProductsView,
      exact: true,
    });
    routes.push({
      path: TRANSLATION_LOGS_ROUTE_PATH,
      label: "Translation Logs",
      component: TranslationLogsView,
      exact: true,
    });
    routes.push({
      path: PRODUCT_FORM_ROUTE_PATH,
      label: "Product Details",
      component: ProductForm,
      exact: true,
    });
    routes.push({
      path: PUSH_NOTIFICATIONS_ROUTE_PATH,
      label: "Notifications",
      component: PushNotificationsView,
      exact: true,
    });
    routes.push({
      path: GROUPS_ROUTE_PATH,
      label: "Groups",
      component: GroupsView,
      exact: true,
    });
    routes.push({
      path: CURRENCIES_ROUTE_PATH,
      label: "Currencies",
      component: CurrenciesView,
      exact: true,
    });
    routes.push({
      path: SOS_CALLS_ROUTE_PATH,
      label: "SOS Calls",
      component: SOSCallsView,
      exact: true,
    });
    routes.push({
      path: ADVERTS_ROUTE_PATH,
      label: "Adverts",
      component: AdvertsView,
      exact: true,
    });
    routes.push({
      path: SETTINGS_ROUTE_PATH,
      label: "Settings",
      component: SettingsForm,
      exact: true,
    });
    if (this.userDetails.isSuperAdmin === true) {
      routes.push({
        path: USERS_ROUTE_PATH,
        label: "Users",
        component: UsersView,
        exact: true,
      });

      routes.push({
        path: LOOKUPSROUTE_PATH,
        label: "LookupsView",
        component: LookupsView,
        exact: true,
      });
    }
    return routes;
  }
}
