import { Dialog } from "primereact/dialog";
import { Messages } from "primereact/messages";
import { ReactNode, useEffect, useRef, useState } from "react";
import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { FormFieldTypes } from "../app_utils/constants/FormFieldTypes";
import {
  getFormFieldComponent,
  validateEmptyField,
} from "../app_utils/components/FormFieldTemplates";
import {
  accountLabelTemplate,
  formatJSDate,
  formatString,
  toReadableDate,
} from "../app_utils/utils/Utils";
import { MISSING_FORM_INPUT_MESSAGE } from "../app_utils/constants/ErrorMessages";
import {
  CSS_COL_12,
  CSS_COL_6,
  MAXIMUM_RECORDS_PER_PAGE,
} from "../app_utils/constants/Constants";
import { BaseApiServiceImpl } from "../app_utils/api/BaseApiServiceImpl";
import { MessageUtils } from "../app_utils/utils/MessageUtils";
import * as labels from "../app_utils/constants/Labels";

interface ModalType {
  children?: ReactNode;
  messageRef?: any;
  memberObject: any;
  reloadFn: any;
  isOpen: boolean;
  toggle: () => void;
}

const MemberFormDialogView = (props: ModalType) => {
  const [id, setId] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(null); // Assuming you'll handle date with a library like moment.js or similar
  const [age, setAge] = useState(null);
  const [ninOrRid, setNinOrRid] = useState("");
  const [phone, setPhone] = useState("");
  const [nationality, setNationality] = useState(null);
  const [district, setDistrict] = useState(null);
  const [watershed, setWatershed] = useState(null);
  const [subCounty, setSubCounty] = useState(null);
  const [parish, setParish] = useState(null);
  const [village, setVillage] = useState(null);

  const [nationalities, setNationalities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [watersheds, setWatersheds] = useState([]);
  const [subCounties, setSubCounties] = useState([]);
  const [parishes, setParishes] = useState([]);
  const [villages, setVillages] = useState([]);

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const message = useRef<any>();

  /**
   * This hook is called when the object is changed. This happens
   * in the parent view when the is changed
   */
  useEffect(() => {
    fetchNationalitiesFromServer();
    fetchVillagesFromServer();
  }, []);

  useEffect(() => {
    populateForm(props?.memberObject?.member);
    fetchNationalitiesFromServer();
    fetchVillagesFromServer();
  }, [props?.memberObject]);

  /**
   * This clears the form by setting form values to null
   */
  const clearForm = () => {
    populateForm(null);
  };
  const fetchNationalitiesFromServer = () => {
    new BaseApiServiceImpl("/api/v1/lookups/lookup-values")
      .getRequestWithJsonResponse({
        offset: 0,
        limit: 200,
        commaSeparatedTypeIds: "0,4,6",
      })
      .then(async (response) => {
        const result = response?.records;
        setNationalities(result.filter((item: any) => item.typeId == 4));
        setDistricts(result.filter((item: any) => item.typeId == 0));
        setWatersheds(result.filter((item: any) => item.typeId == 6));
      })
      .catch((error) => {
        MessageUtils.showErrorMessage(message, error.message);
      });
  };

  const fetchVillagesFromServer = () => {
    new BaseApiServiceImpl("/api/v1/lookups/lookup-values")
      .getRequestWithJsonResponse({
        offset: 0,
        limit: 200,
        commaSeparatedTypeIds: "9,3,1",
      })
      .then(async (response) => {
        const result = response?.records;
        setVillages(result.filter((item: any) => item.typeId == 9));
        setSubCounties(result.filter((item: any) => item.typeId == 3));
        setParishes(result.filter((item: any) => item.typeId == 1));
      })
      .catch((error) => {
        MessageUtils.showErrorMessage(message, error.message);
      });
  };

  const populateForm = (dataObject: any) => {
    console.log(JSON.stringify(dataObject));
    setId(dataObject?.id);
    setFirstName(dataObject?.firstName);
    setLastName(dataObject?.lastName);
    setPhone(dataObject?.phone);
    setNinOrRid(dataObject?.ninOrRid);
    setDateOfBirth(dataObject?.dateOfBirth);
    setNationality(dataObject?.nationality?.id);
    setDistrict(dataObject?.district?.id);
    setSubCounty(dataObject?.subCounty?.id);
    setWatershed(dataObject?.watershed?.id);
    setVillage(dataObject?.village?.id);
    setParish(dataObject?.parish?.id);
  };

  /**
   * This is a list of member form fields
   */
  let memberFormFields: any = [
    {
      type: FormFieldTypes.TEXT.toString(),
      label: "First Name",
      value: firstName,
      onChange: setFirstName,
      width: CSS_COL_6,
    },
    {
      type: FormFieldTypes.TEXT.toString(),
      label: "Last Name",
      value: lastName,
      onChange: setLastName,
      width: CSS_COL_6,
    },
    {
      type: FormFieldTypes.DATE.toString(),
      label: "Date of Birth",
      value: dateOfBirth,
      onChange: setDateOfBirth,
      width: CSS_COL_6,
    },

    {
      type: FormFieldTypes.TEXT.toString(),
      label: "NIN or RID",
      value: ninOrRid,
      onChange: setNinOrRid,
      width: CSS_COL_6,
    },
    {
      type: FormFieldTypes.TEXT.toString(),
      label: "Phone",
      value: phone,
      onChange: setPhone,
      width: CSS_COL_6,
    },
    {
      type: FormFieldTypes.DROPDOWN.toString(),
      label: "Nationality",
      value: nationality,
      onChange: setNationality,
      options: nationalities,
      optionValue: "id",
      optionLabel: "value",
      width: CSS_COL_6,
    },
    {
      type: FormFieldTypes.DROPDOWN.toString(),
      label: "District",
      value: district,
      onChange: setDistrict,
      options: districts,
      optionValue: "id",
      optionLabel: "value",
      width: CSS_COL_6,
    },
    {
      type: FormFieldTypes.DROPDOWN.toString(),
      label: "Watershed",
      value: watershed,
      onChange: setWatershed,
      options: watersheds,
      optionValue: "id",
      optionLabel: "value",
      width: CSS_COL_6,
    },
    {
      type: FormFieldTypes.DROPDOWN.toString(),
      label: "Sub County",
      value: subCounty,
      onChange: setSubCounty,
      options: subCounties,
      optionValue: "id",
      optionLabel: "value",
      width: CSS_COL_6,
    },
    {
      type: FormFieldTypes.DROPDOWN.toString(),
      label: "Parish",
      value: parish,
      onChange: setParish,
      options: parishes,
      optionValue: "id",
      optionLabel: "value",
      width: CSS_COL_6,
    },
    {
      type: FormFieldTypes.DROPDOWN.toString(),
      label: "Village",
      value: village,
      onChange: setVillage,
      options: villages,
      optionValue: "id",
      optionLabel: "value",
      width: CSS_COL_6,
    },
  ];

  /**
   * This loops through the member object fields array to create the fields elements for
   * display
   */
  let memberFields = memberFormFields.map((memberObjectField: any) => {
    return getFormFieldComponent(memberObjectField);
  });

  /**
   * This clears the hint messages
   */
  const clearHints = () => {
    memberFormFields.forEach((formField: any) => {
      if (formField.isValidHint) {
        formField.setHint(null);
      }
    });
  };

  /**
   * This validates the form fields that have isValidHint attributes and sets their corresponding hints if the field validation
   * fails
   * @returns boolean
   */
  const validateForm = () => {
    clearHints();
    let isFormValid: boolean = true;

    memberFormFields.forEach((formField: any) => {
      if (
        formField.setHint &&
        (formField.value === null ||
          formField.value === "" ||
          formField.value === undefined)
      ) {
        isFormValid = false;
        formField.setHint(
          formatString(MISSING_FORM_INPUT_MESSAGE, formField.label)
        );
      }
    });

    return isFormValid;
  };

  /**
   * This submits a save member request to the backoffice
   */
  const saveMember = () => {
    let memberData: any = {
      id,
      firstName,
      lastName,
      dateOfBirth: formatJSDate(dateOfBirth, false),
      age,
      ninOrRid,
      phone,
      nationality,
      district,
      watershed,
      subCounty,
      parish,
      village,
    };

    if (validateForm()) {
      setIsSaving(true);
      new BaseApiServiceImpl("/api/v1/members")
        .postRequestWithJsonResponse(memberData)
        .then(async (response) => {
          setIsSaving(false);
          clearForm();
          MessageUtils.showSuccessMessage(
            props?.messageRef,
            labels.LABEL_RECORD_SAVED_SUCCESSFULLY
          );
          closeDialog();
          props?.reloadFn();
        })
        .catch((error) => {
          setIsSaving(false);
          MessageUtils.showErrorMessage(message, error.message);
        });
    }
  };

  /**
   * This closes the dialog
   */
  const closeDialog = () => {
    props.toggle();
  };

  /**
   * This is the footer of the modal dialog
   */
  const memberDetailsDialogFooter = (
    <>
      <Button
        label={labels.LABEL_CANCEL}
        icon={PrimeIcons.TIMES}
        className="p-button-text"
        onClick={closeDialog}
      />
      <Button
        label={labels.LABEL_SAVE}
        icon={PrimeIcons.SAVE}
        className="p-button-secondary"
        onClick={saveMember}
        loading={isSaving}
      />
    </>
  );

  return (
    <Dialog
      minX={200}
      visible={props.isOpen}
      header={"Create member form"}
      footer={memberDetailsDialogFooter}
      modal
      className="p-fluid"
      onHide={closeDialog}
      style={{ width: "50vw" }}
    >
      <Messages ref={message} />
      <div className="grid">
        <div className="col-12">
          <Messages ref={message} style={{ width: "100%" }} />
        </div>
        {memberFields}
      </div>
    </Dialog>
  );
};

export default MemberFormDialogView;
