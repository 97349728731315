import { Messages } from "primereact/messages";
import { useEffect, useRef, useState } from "react";
import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { FormFieldTypes } from "../app_utils/constants/FormFieldTypes";
import { getFormFieldComponent } from "../app_utils/components/FormFieldTemplates";
import { formatString } from "../app_utils/utils/Utils";
import { MISSING_FORM_INPUT_MESSAGE } from "../app_utils/constants/ErrorMessages";
import { BaseApiServiceImpl } from "../app_utils/api/BaseApiServiceImpl";
import { MessageUtils } from "../app_utils/utils/MessageUtils";
import * as labels from "../app_utils/constants/Labels";
import {
  CSS_COL_12,
  CSS_COL_2,
  CSS_COL_4,
  CSS_COL_6,
  CSS_COL_8,
} from "../app_utils/constants/Constants";
import { BreadCrumb } from "primereact/breadcrumb";
import { useHistory, useParams } from "react-router-dom";
import { HOME_ROUTE_PATH } from "../app_utils/route_paths/resolver/PageRoutes";

interface RecordType {
  id: number;
  appName: string;

  cardDepositChargeRate: number;
  mobileMoneyDepositChargeRate: number;
  cardDepositChargeType: any;
  mobileMoneyDepositChargeType: any;

  withdrawChargeRate: number;
  withdrawFlatChargeType: any;

  transferChargeRate: number;
  transferChargeType: any;

  maximumCircleMembers: number;
  maximumCircleContacts: number;
}
const CHARGE_TYPES = [
  { name: "None", id: "None" },
  { name: "Flat", id: "Flat" },
  { name: "Percentage", id: "Percentage" },
];
const SettingsForm = () => {
  const [record, setRecord] = useState<RecordType>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const message = useRef<any>();
  const history = useHistory();
  /**
   * These are the bread crumbs that serve as the title of the page
   */
  const breadcrumbHome = {
    icon: "pi pi-home",
    command: () => {
      history.push(HOME_ROUTE_PATH);
    },
  };

  const breadcrumbItems = [
    {
      label: `System Settings`,
      icon: PrimeIcons.USERS,
    },
  ];

  /**
   * This fetches counties from the back office using the search parameters
   */
  const fetchRecordFromServer = () => {
    setIsLoading(true);

    new BaseApiServiceImpl("/api/v1/settings")
      .getRequestWithJsonResponse({})
      .then(async (response) => {
        setIsLoading(false);
        setRecord(response?.data);
      })
      .catch((error) => {
        setIsLoading(false);
        MessageUtils.showErrorMessage(message, error.message);
      });
  };

  /**
   * This hook is called everytime the page is loaded
   */
  useEffect(() => {
    fetchRecordFromServer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /**
   * This is a list of user form fields
   */
  let userFormFields: any = [
    {
      type: FormFieldTypes.TEXT.toString(),
      label: "App Name",
      value: record?.appName,
      onChange: (e: any) => setRecord((prevState: any) => ({ ...prevState, name: e })),
      width: CSS_COL_12,
    },
    {
      type: FormFieldTypes.NUMBER.toString(),
      label: "Maximum Circle Contacts",
      value: record?.maximumCircleContacts,
      onChange: (e: any) =>
        setRecord((prevState: any) => ({ ...prevState, maximumCircleContacts: e })),
      width: CSS_COL_6,
    },
    {
      type: FormFieldTypes.NUMBER.toString(),
      label: "Maximum Circle Members",
      value: record?.maximumCircleMembers,
      onChange: (e: any) =>
        setRecord((prevState: any) => ({ ...prevState, maximumCircleMembers: e })),
      width: CSS_COL_6,
    },
    {
      type: FormFieldTypes.DROPDOWN.toString(),
      label: "Card Deposit Charge type",
      value: record?.cardDepositChargeType,
      optionValue: "name",
      optionLabel: "id",
      options: CHARGE_TYPES,
      onChange: (e: any) =>
        setRecord((prevState: any) => ({ ...prevState, cardDepositChargeType: e })),
      width: CSS_COL_2,
    },
    {
      type: FormFieldTypes.FLOAT.toString(),
      label: "Card Deposit Charge",
      value: record?.cardDepositChargeRate,
      onChange: (e: any) =>
        setRecord((prevState: any) => ({ ...prevState, cardDepositChargeRate: e })),
      width: CSS_COL_4,
    },

    {
      type: FormFieldTypes.DROPDOWN.toString(),
      label: "Momo Deposit Charge type",
      value: record?.mobileMoneyDepositChargeType,
      optionValue: "name",
      optionLabel: "id",
      options: CHARGE_TYPES,
      onChange: (e: any) =>
        setRecord((prevState: any) => ({ ...prevState, mobileMoneyDepositChargeType: e })),
      width: CSS_COL_2,
    },
    {
      type: FormFieldTypes.FLOAT.toString(),
      label: "Momo Deposit Charge",
      value: record?.mobileMoneyDepositChargeRate,
      onChange: (e: any) =>
        setRecord((prevState: any) => ({ ...prevState, mobileMoneyDepositChargeRate: e })),
      width: CSS_COL_4,
    },

    {
      type: FormFieldTypes.DROPDOWN.toString(),
      label: "Withdraw Charge is flat fee??",
      value: record?.withdrawFlatChargeType,
      optionValue: "name",
      optionLabel: "id",
      options: CHARGE_TYPES,
      onChange: (e: any) =>
        setRecord((prevState: any) => ({ ...prevState, withdrawFlatChargeType: e })),
      width: CSS_COL_2,
    },

    {
      type: FormFieldTypes.FLOAT.toString(),
      label: "Withdraw Charge",
      value: record?.withdrawChargeRate,
      onChange: (e: any) =>
        setRecord((prevState: any) => ({ ...prevState, withdrawChargeRate: e })),
      width: CSS_COL_4,
    },

    {
      type: FormFieldTypes.DROPDOWN.toString(),
      label: "Transfer charge Type",
      value: record?.transferChargeType,
      optionValue: "name",
      optionLabel: "id",
      options: CHARGE_TYPES,
      onChange: (e: any) =>
        setRecord((prevState: any) => ({ ...prevState, transferChargeType: e })),
      width: CSS_COL_2,
    },

    {
      type: FormFieldTypes.FLOAT.toString(),
      label: "Transfer Charge",
      value: record?.transferChargeRate,
      onChange: (e: any) =>
        setRecord((prevState: any) => ({ ...prevState, transferChargeRate: e })),
      width: CSS_COL_4,
    },
  ];

  /**
   * This loops through the user object fields array to create the fields elements for
   * display
   */
  let userFields = userFormFields.map((userObjectField: any) => {
    return getFormFieldComponent(userObjectField);
  });

  /**s
   * This clears the hint messages
   */
  const clearHints = () => {
    userFormFields.forEach((formField: any) => {
      if (formField.isValidHint) {
        formField.setHint(null);
      }
    });
  };

  /**
   * This validates the form fields that have isValidHint attributes and sets their corresponding hints if the field validation
   * fails
   * @returns boolean
   */
  const validateForm = () => {
    clearHints();
    let isFormValid: boolean = true;

    userFormFields.forEach((formField: any) => {
      if (
        formField.setHint &&
        (formField.value === null || formField.value === "" || formField.value === undefined)
      ) {
        isFormValid = false;
        formField.setHint(formatString(MISSING_FORM_INPUT_MESSAGE, formField.label));
      }
    });

    return isFormValid;
  };

  /**
   * This submits a save user request to the backoffice
   */
  const saveUser = () => {
    if (validateForm()) {
      setIsLoading(true);
      new BaseApiServiceImpl("/api/v1/settings")
        .postRequestWithJsonResponse(record)
        .then(async (response) => {
          setIsLoading(false);
          MessageUtils.showSuccessMessage(message, labels.LABEL_RECORD_SAVED_SUCCESSFULLY);
          closeForm();
        })
        .catch((error) => {
          setIsLoading(false);
          MessageUtils.showErrorMessage(message, error.message);
        });
    }
  };

  /**
   * This closes the dialog
   */
  const closeForm = () => {
    history.goBack();
  };

  /**
   * This is the footer of the modal dialog
   */
  const userDetailsDialogFooter = (
    <div className="col-8">
      <Button
        label={labels.LABEL_CANCEL}
        icon={PrimeIcons.TIMES}
        className="p-button-text"
        onClick={closeForm}
      />
      <Button
        label={labels.LABEL_SAVE}
        icon={PrimeIcons.SAVE}
        className="p-button-secondary"
        onClick={saveUser}
        loading={isLoading}
      />
    </div>
  );

  return (
    <div className="grid mt-2">
      <div className="col-8 flex justify-content-start flex-wrap">
        <BreadCrumb home={breadcrumbHome} model={breadcrumbItems} />
      </div>
      <div className="col-12">
        <div className="card">
          <Messages ref={message} />
          <div className="grid">
            {userFields}

            {userDetailsDialogFooter}
            <Messages ref={message} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsForm;
