import { Dialog } from "primereact/dialog";
import { Messages } from "primereact/messages";
import { ReactNode, useEffect, useRef, useState } from "react";
import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { FormFieldTypes } from "../app_utils/constants/FormFieldTypes";
import {
  getFormFieldComponent,
  validateEmptyField,
} from "../app_utils/components/FormFieldTemplates";
import {
  formatJSDate,
  formatString,
  isNotEmpty,
  validatePhoneNumber,
} from "../app_utils/utils/Utils";
import { MISSING_FORM_INPUT_MESSAGE } from "../app_utils/constants/ErrorMessages";
import { BaseApiServiceImpl } from "../app_utils/api/BaseApiServiceImpl";
import { MessageUtils } from "../app_utils/utils/MessageUtils";
import * as labels from "../app_utils/constants/Labels";
import {
  ADVERT_TYPES,
  CSS_COL_12,
  CSS_COL_2,
  CSS_COL_3,
  CSS_COL_4,
  CSS_COL_6,
  CSS_COL_8,
  PRODUCT_LISTING_TYPE,
  RECORD_STATUSES,
} from "../app_utils/constants/Constants";
import { BreadCrumb } from "primereact/breadcrumb";
import { useHistory, useParams } from "react-router-dom";
import {
  HOME_ROUTE_PATH,
  PRODUCTS_VIEW_ROUTE_PATH,
} from "../app_utils/route_paths/resolver/PageRoutes";

interface RecordType {
  id: number;
  name: string;
  description: string;
  image1Url: string;
  image2Url: string;
  image3Url: string;
  image4Url: string;
  listingType: string;
  image1: any;
  image2: any;
  image3: any;
  image4: any;
  price: number;

  onDiscount: boolean;

  discountedPrice: number;

  quantityAvailable: number;
  categoryId: number;
}
const initObject: RecordType = {
  id: 0,
  name: "New Product",
  description: "New product description",
  image1Url: "",
  image2Url: "",
  image3Url: "",
  image4Url: "",
  listingType: "",
  image1: null,
  image2: null,
  image3: null,
  image4: null,
  price: 1,
  onDiscount: false,
  discountedPrice: 0,
  quantityAvailable: 1,
  categoryId: 0,
};
const ProductForm = () => {
  let urlParams = useParams();
  let id = JSON.parse(JSON.stringify(urlParams)).id;
  const [record, setRecord] = useState<RecordType>(initObject);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [categories, setCategories] = useState<any>([]);
  const message = useRef<any>();
  const history = useHistory();
  /**
   * These are the bread crumbs that serve as the title of the page
   */
  const breadcrumbHome = {
    icon: "pi pi-home",
    command: () => {
      history.push(HOME_ROUTE_PATH);
    },
  };

  const breadcrumbItems = [
    {
      label: `Product Listing`,
      icon: PrimeIcons.FLAG,
      command: () => {
        history.replace(PRODUCTS_VIEW_ROUTE_PATH);
      },
    },
    {
      label: `Product ${record?.name}`,
      icon: PrimeIcons.USERS,
    },
  ];
  /**
   * This clears the form by setting form values to null
   */
  const clearForm = () => {
    setRecord(initObject);
  };
  /**
   * This fetches counties from the back office using the search parameters
   */
  const fetchRecordFromServer = () => {
    setIsLoading(true);

    new BaseApiServiceImpl("/api/v1/products/" + id)
      .getRequestWithJsonResponse({})
      .then(async (response) => {
        setIsLoading(false);
        let resp = response?.data;
        setRecord({ ...resp, categoryId: resp.category.id });
      })
      .catch((error) => {
        setIsLoading(false);
        MessageUtils.showErrorMessage(message, error.message);
      });
  };

  /**
   * This hook is called everytime the page is loaded
   */
  useEffect(() => {
    fetchCategories();
    if (id > 0) {
      fetchRecordFromServer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /**
   * This is a list of user form fields
   */
  let userFormFields: any = [
    {
      type: FormFieldTypes.TEXT.toString(),
      label: "Product or Service Name",
      value: record?.name,
      onChange: (e: any) => setRecord((prevState: any) => ({ ...prevState, name: e })),
      width: CSS_COL_8,
    },

    {
      type: FormFieldTypes.NUMBER.toString(),
      label: "Quantity",
      value: record?.quantityAvailable,
      onChange: (e: any) => setRecord((prevState: any) => ({ ...prevState, quantityAvailable: e })),
      width: CSS_COL_3,
    },
    {
      type: FormFieldTypes.DROPDOWN.toString(),
      label: "Category",
      value: record?.categoryId,
      options: categories,
      optionLabel: "name",
      optionValue: "id",
      onChange: (e: any) => setRecord((prevState: any) => ({ ...prevState, categoryId: e })),
      width: CSS_COL_6,
    },
    {
      type: FormFieldTypes.DROPDOWN.toString(),
      label: "Listing Type",
      value: record?.listingType,
      options: PRODUCT_LISTING_TYPE,
      optionLabel: "name",
      optionValue: "id",
      onChange: (e: any) => setRecord((prevState: any) => ({ ...prevState, listingType: e })),
      width: CSS_COL_6,
    },
    {
      type: FormFieldTypes.FLOAT.toString(),
      label: "Price (USD)",
      value: record?.price,
      onChange: (e: any) => setRecord((prevState: any) => ({ ...prevState, price: e })),
      width: CSS_COL_4,
    },
    {
      type: FormFieldTypes.BOOLEAN.toString(),
      label: "On discount?",
      value: record?.onDiscount,
      onChange: (e: any) => setRecord((prevState: any) => ({ ...prevState, onDiscount: e })),
      width: CSS_COL_2,
    },
    {
      type: FormFieldTypes.FLOAT.toString(),
      label: "Discounted Price (USD)",
      value: record?.discountedPrice,
      hidden: record?.onDiscount != true,
      onChange: (e: any) => setRecord((prevState: any) => ({ ...prevState, discountedPrice: e })),
      width: CSS_COL_4,
    },
    {
      type: FormFieldTypes.TEXTAREA.toString(),
      label: "Description",
      value: record?.description,
      onChange: (e: any) => setRecord((prevState: any) => ({ ...prevState, description: e })),
      width: CSS_COL_12,
    },
    {
      type: FormFieldTypes.IMAGE.toString(),
      label: "Image 1",
      value: record?.image1Url,
      onChange: (e: any) => setRecord((prevState: any) => ({ ...prevState, image1: e })),
      width: CSS_COL_6,
    },

    {
      type: FormFieldTypes.IMAGE.toString(),
      label: "Image 2",
      value: record?.image2Url,
      onChange: (e: any) => setRecord((prevState: any) => ({ ...prevState, image2: e })),
      width: CSS_COL_6,
    },
    {
      type: FormFieldTypes.IMAGE.toString(),
      label: "Image 3",
      value: record?.image3Url,
      onChange: (e: any) => setRecord((prevState: any) => ({ ...prevState, image3: e })),
      width: CSS_COL_6,
    },
    {
      type: FormFieldTypes.IMAGE.toString(),
      label: "Image 4",
      value: record?.image4Url,
      onChange: (e: any) => setRecord((prevState: any) => ({ ...prevState, image4: e })),
      width: CSS_COL_6,
    },
  ];

  /**
   * This loops through the user object fields array to create the fields elements for
   * display
   */
  let userFields = userFormFields.map((userObjectField: any) => {
    return getFormFieldComponent(userObjectField);
  });

  /**
   * This clears the hint messages
   */
  const clearHints = () => {
    userFormFields.forEach((formField: any) => {
      if (formField.isValidHint) {
        formField.setHint(null);
      }
    });
  };

  /**
   * This validates the form fields that have isValidHint attributes and sets their corresponding hints if the field validation
   * fails
   * @returns boolean
   */
  const validateForm = () => {
    clearHints();
    let isFormValid: boolean = true;

    userFormFields.forEach((formField: any) => {
      if (
        formField.setHint &&
        (formField.value === null || formField.value === "" || formField.value === undefined)
      ) {
        isFormValid = false;
        formField.setHint(formatString(MISSING_FORM_INPUT_MESSAGE, formField.label));
      }
    });

    return isFormValid;
  };
  /**
   * This fetches counties from the back office using the search parameters
   */
  const fetchCategories = () => {
    new BaseApiServiceImpl("/api/v1/product-categories")
      .getRequestWithJsonResponse({ offset: 0, limit: 10 })
      .then(async (response) => {
        setCategories(response?.records);
      })
      .catch((error) => {
        MessageUtils.showErrorMessage(message, error.message);
      });
  };
  /**
   * This submits a save user request to the backoffice
   */
  const saveUser = () => {
    let payload: any = record;
    let accountProfileFormData: FormData = new FormData();

    if (isNotEmpty(payload?.image1)) {
      accountProfileFormData.append("image1", payload?.image1);
      payload.image1 = null;
    }
    if (isNotEmpty(payload?.image2)) {
      accountProfileFormData.append("image2", payload?.image2);
      payload.image2 = null;
    }
    if (isNotEmpty(payload?.image3)) {
      accountProfileFormData.append("image3", payload?.image3);
      payload.image3 = null;
    }
    if (isNotEmpty(payload?.image4)) {
      accountProfileFormData.append("image4", payload?.image4);
      payload.image4 = null;
    }
    payload.startDate = formatJSDate(payload.startDate);
    payload.endDate = formatJSDate(payload.endDate);
    accountProfileFormData.append(
      "dto",
      new Blob([JSON.stringify(payload)], { type: "application/json" })
    );
    if (validateForm()) {
      setIsLoading(true);
      new BaseApiServiceImpl("/api/v1/products")
        .postMultipartWithJsonResponse(accountProfileFormData)
        .then(async (response) => {
          setIsLoading(false);
          clearForm();
          MessageUtils.showSuccessMessage(message, labels.LABEL_RECORD_SAVED_SUCCESSFULLY);
          closeForm();
        })
        .catch((error) => {
          setIsLoading(false);
          MessageUtils.showErrorMessage(message, error.message);
        });
    }
  };

  /**
   * This closes the dialog
   */
  const closeForm = () => {
    history.goBack();
  };

  /**
   * This is the footer of the modal dialog
   */
  const userDetailsDialogFooter = (
    <>
      <Button
        label={labels.LABEL_CANCEL}
        icon={PrimeIcons.TIMES}
        className="p-button-text"
        onClick={closeForm}
      />
      <Button
        label={labels.LABEL_SAVE}
        icon={PrimeIcons.SAVE}
        className="p-button-secondary"
        onClick={saveUser}
        loading={isLoading}
      />
    </>
  );

  return (
    <div className="grid mt-2">
      <div className="col-8 flex justify-content-start flex-wrap">
        <BreadCrumb home={breadcrumbHome} model={breadcrumbItems} />
      </div>
      <div className="col-12">
        <div className="card">
          <Messages ref={message} />
          <div className="grid">
            {userFields}

            {userDetailsDialogFooter}
            <Messages ref={message} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductForm;
