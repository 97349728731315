import { Dialog } from "primereact/dialog";
import { Messages } from "primereact/messages";
import { ReactNode, useEffect, useRef, useState } from "react";
import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { generatePath } from "react-router-dom";
import { FormFieldTypes } from "../app_utils/constants/FormFieldTypes";
import {
  getFormFieldComponent,
  validateEmptyField,
} from "../app_utils/components/FormFieldTemplates";
import { CSS_COL_12 } from "../app_utils/constants/Constants";
import { formatString, sanitizeErrorMessage } from "../app_utils/utils/Utils";
import { MISSING_FORM_INPUT_MESSAGE } from "../app_utils/constants/ErrorMessages";
import { BaseApiServiceImpl } from "../app_utils/api/BaseApiServiceImpl";
import { MessageUtils } from "../app_utils/utils/MessageUtils";
import { LABEL_CANCEL } from "../app_utils/constants/Labels";

interface ModalType {
  children?: ReactNode;
  messageRef?: any;
  saveFn: (reason: any) => void;
  isOpen: boolean;
  title: string;
  reasonType: string | null;

  toggle: () => void;
}

const SuperAdminActionDialog = (props: ModalType) => {
  const [reason, setReason] = useState<any>(null);
  const [reasons, setReasons] = useState<any>([]);
  const [isValidStatusHint, setIsValidStatusHint] = useState<any>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const message = useRef<any>();

  /**
   * This hook is called when the form field object is changed. This happens
   * in the parent view when the form field is changed
   */
  useEffect(() => {
    fetchReasonsFromServer();
    populateForm(null);
  }, [props?.title]);

  /**
   * This clears the form by setting form values to null
   */
  const clearForm = () => {
    populateForm(null);
  };
  /**
   * This clears the form by setting form values to null
   */
  const populateForm = (fieldObject: any) => {
    setReason(fieldObject?.reason);
  };

  /**
   * This is a list of  form fields
   */
  let formFields: any = [
    {
      type: FormFieldTypes.DROPDOWN.toString(),
      id: "Note",
      label: "Note",
      value: reason,
      onChange: setReason,
      options: reasons,
      hidden: props.reasonType == null,
      optionLabel: "value",
      optionValue: "id",
      setHint: setIsValidStatusHint,
      isValidHint: isValidStatusHint,
      validateFieldFn: validateEmptyField,
      width: CSS_COL_12,
    },
    {
      type: FormFieldTypes.TEXTAREA.toString(),
      id: "Note",
      label: "Note",
      value: reason,
      onChange: setReason,
      hidden: props.reasonType != null,
      setHint: setIsValidStatusHint,
      isValidHint: isValidStatusHint,
      validateFieldFn: validateEmptyField,
      width: CSS_COL_12,
    },
  ];

  /**
   * This loops through the form field form object fields array to create the fields elements for
   * display
   */
  let fields = formFields.map((kycAccountObjectField: any) => {
    return getFormFieldComponent(kycAccountObjectField);
  });

  /**
   * This clears the hint messages
   */
  const clearHints = () => {
    fields.forEach((formField: any) => {
      if (formField?.hidden !== true && formField?.isValidHint) {
        formField.setHint(null);
      }
    });
  };

  /**
   * This validates the form fields that have isValidHint attributes and sets their corresponding hints if the field validation
   * fails
   * @returns boolean
   */
  const validateForm = () => {
    clearHints();
    let isFormValid: boolean = true;

    formFields.forEach((formField: any) => {
      if (
        formField.setHint &&
        formField.hidden !== true &&
        (formField.value === null || formField.value === "" || formField.value === undefined)
      ) {
        isFormValid = false;
        formField.setHint(formatString(MISSING_FORM_INPUT_MESSAGE, formField.label));
      }
    });

    return isFormValid;
  };
  const fetchReasonsFromServer = () => {
    if (props.reasonType != null) {
      new BaseApiServiceImpl("/api/v1/lookups/lookup-values")
        .getRequestWithJsonResponse({
          offset: 0,
          limit: 10,
          commaSeparatedTypeIds: props.reasonType,
        })
        .then(async (response) => {
          setReasons(response?.records);
        })
        .catch((error) => {
          MessageUtils.showErrorMessage(message, sanitizeErrorMessage(error, "Dropdown Reasons"));
        });
    }
  };
  /**
   * This submits a save form field request to the backoffice
   */
  const saveRecord = () => {
    if (validateForm()) {
      props?.saveFn(reason);
      closeDialog();
    }
  };

  /**
   * This closes the dialog
   */
  const closeDialog = () => {
    props.toggle();
  };

  /**
   * This is the footer of the modal dialog
   */
  const dialogFooter = (
    <>
      <Button
        label={LABEL_CANCEL}
        icon={PrimeIcons.TIMES}
        className="p-button-text"
        onClick={closeDialog}
      />
      <Button
        label={"Submit"}
        icon={PrimeIcons.SEND}
        disabled={reason === null || reason === undefined}
        className="p-button-secondary"
        onClick={saveRecord}
        loading={isSaving}
      />
    </>
  );

  return (
    <Dialog
      visible={props.isOpen}
      header={props?.title}
      footer={dialogFooter}
      modal
      className="p-fluid"
      onHide={closeDialog}
      style={{ width: "50vw" }}
    >
      <Messages ref={message} />
      <div className="grid">
        <div className="col-12">
          <Messages ref={message} style={{ width: "100%" }} />
        </div>
        {fields}
      </div>
    </Dialog>
  );
};

export default SuperAdminActionDialog;
