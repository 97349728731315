import { Dialog } from "primereact/dialog";
import { Messages } from "primereact/messages";
import { ReactNode, useEffect, useRef, useState } from "react";
import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { generatePath } from "react-router-dom";
import { FileUpload } from "primereact/fileupload";
import { BaseApiServiceImpl } from "../app_utils/api/BaseApiServiceImpl";
import { MessageUtils } from "../app_utils/utils/MessageUtils";
import { LABEL_CANCEL, LABEL_SAVE } from "../app_utils/constants/Labels";

interface ModalType {
  children?: ReactNode;
  messageRef?: any;
  reloadFn: any;
  toggleOpen: any;
  isOpen: boolean;
}

const UploadFormDialog = (props: ModalType) => {
  const [attachment, setAttachment] = useState<any>(null);

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const message = useRef<any>();

  /**
   * This hook is called when the form field object is changed. This happens
   * in the parent view when the form field is changed
   */
  useEffect(() => {}, []);

  /**
   * This clears the form by setting form values to null
   */
  const clearForm = () => {
    populateForm(null);
  };
  /**
   * This clears the form by setting form values to null
   */
  const populateForm = (fieldObject: any) => {
    setAttachment(fieldObject?.attachment);
  };

  /**
   * This submits a save form field request to the backoffice
   */
  const saveRecord = () => {
    setIsSaving(true);
    let payload = new FormData();
    payload.append("file", attachment);

    new BaseApiServiceImpl("/api/v1/members/upload")
      .postMultipartWithJsonResponse(payload)
      .then(async (response) => {
        setIsSaving(false);
        clearForm();

        setIsSaving(false);
        MessageUtils.showSuccessMessage(
          message,
          `Successfully imported ${response.data}`
        );
        closeDialog();
        props?.reloadFn();
      })
      .catch((error) => {
        setIsSaving(false);
        MessageUtils.showErrorMessage(message, error.message);
      });
  };

  /**
   * This closes the dialog
   */
  const closeDialog = () => {
    props.toggleOpen(false);
  };

  /**
   * This is the footer of the modal dialog
   */
  const dialogFooter = (
    <>
      <Button
        label={LABEL_CANCEL}
        icon={PrimeIcons.TIMES}
        className="p-button-text"
        onClick={closeDialog}
      />
      <Button
        label={LABEL_SAVE}
        icon={PrimeIcons.SAVE}
        className="p-button-secondary"
        onClick={saveRecord}
        loading={isSaving}
      />
    </>
  );
  /**
   * This uploads the csv and sets the variable attachment. It clears the file
   * form once completed
   * @param event
   */
  const uploadPhoto = (event: any) => {
    setAttachment(event.files[0]);
    event.options.clear();
  };
  return (
    <Dialog
      visible={props.isOpen}
      header={"Upload Members"}
      footer={dialogFooter}
      modal
      className="p-fluid"
      onHide={closeDialog}
      style={{ width: "50vw" }}
    >
      <Messages ref={message} />
      <div className="grid">
        <div className="col-12">
          <Messages ref={message} style={{ width: "100%" }} />
        </div>

        <div className="field col-4">
          <label htmlFor={"Upload"} className="font-bold">
            {"Upload"}
          </label>
          <FileUpload
            id="attachment"
            name="demo[]"
            mode="basic"
            accept=".csv"
            customUpload
            uploadHandler={uploadPhoto}
            maxFileSize={2000000}
            auto
            chooseLabel={attachment?.name ? attachment?.name : "Pick File"}
          />
          <label className="mt-2">{attachment?.name}</label>
        </div>
      </div>
    </Dialog>
  );
};

export default UploadFormDialog;
