import { Dialog } from "primereact/dialog";
import { Messages } from "primereact/messages";
import { ReactNode, useEffect, useRef, useState } from "react";
import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { FormFieldTypes } from "../app_utils/constants/FormFieldTypes";
import {
  getFormFieldComponent,
  validateEmptyField,
} from "../app_utils/components/FormFieldTemplates";
import {
  formatJSDate,
  formatString,
  isNotEmpty,
  validatePhoneNumber,
} from "../app_utils/utils/Utils";
import { MISSING_FORM_INPUT_MESSAGE } from "../app_utils/constants/ErrorMessages";
import { BaseApiServiceImpl } from "../app_utils/api/BaseApiServiceImpl";
import { MessageUtils } from "../app_utils/utils/MessageUtils";
import * as labels from "../app_utils/constants/Labels";
import {
  ADVERT_TYPES,
  CSS_COL_12,
  CSS_COL_2,
  CSS_COL_3,
  CSS_COL_4,
  CSS_COL_6,
  RECORD_STATUSES,
} from "../app_utils/constants/Constants";

interface ModalType {
  children?: ReactNode;
  messageRef?: any;
  record: any;
  advertTypes: any;
  reloadFn: any;
  isOpen: boolean;
  toggle: () => void;
}
interface RecordType {
  id: 0;
  type: null;
  title: "";
  description: "";
  image: any;
  imageUrl: "";
  hyperlink: "";
  position: "";
  featured: boolean;
  startDate: any;
  endDate: any;
}
const AdvertFormDialogView = (props: ModalType) => {
  const [record, setRecord] = useState<RecordType | null>(props.record);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const message = useRef<any>();

  /**
   * This clears the form by setting form values to null
   */
  const clearForm = () => {
    setRecord(null);
  };

  /**
   * This is a list of user form fields
   */
  let userFormFields: any = [
    {
      type: FormFieldTypes.TEXT.toString(),
      label: "Title",
      value: record?.title,
      onChange: (e: any) => setRecord((prevState: any) => ({ ...prevState, title: e })),
      width: CSS_COL_12,
    },
    {
      type: FormFieldTypes.DROPDOWN.toString(),
      label: "Type",
      value: record?.type,
      onChange: (e: any) => setRecord((prevState: any) => ({ ...prevState, type: e })),
      options: ADVERT_TYPES,
      optionValue: "id",
      optionLabel: "name",
      width: CSS_COL_6,
    },
    {
      type: FormFieldTypes.TEXT.toString(),
      label: "Link",
      value: record?.hyperlink,
      onChange: (e: any) => setRecord((prevState: any) => ({ ...prevState, hyperlink: e })),
      width: CSS_COL_6,
    },

    {
      type: FormFieldTypes.DATE.toString(),
      label: "Start Date",
      value: new Date(record?.startDate),
      onChange: (e: any) => setRecord((prevState: any) => ({ ...prevState, startDate: e })),
      width: CSS_COL_4,
    },
    {
      type: FormFieldTypes.DATE.toString(),
      label: "End Date",
      value: new Date(record?.endDate),
      onChange: (e: any) => setRecord((prevState: any) => ({ ...prevState, endDate: e })),
      width: CSS_COL_4,
    },
    {
      type: FormFieldTypes.BOOLEAN.toString(),
      label: "Is Featured",
      value: record?.featured,
      onChange: (e: any) => setRecord((prevState: any) => ({ ...prevState, featured: e })),
      width: CSS_COL_2,
    },

    {
      type: FormFieldTypes.IMAGE.toString(),
      label: "Image",
      value: record?.imageUrl,
      onChange: (e: any) => setRecord((prevState: any) => ({ ...prevState, image: e })),
      width: CSS_COL_6,
    },
    {
      type: FormFieldTypes.TEXTAREA.toString(),
      label: "Description",
      value: record?.description,
      onChange: (e: any) => setRecord((prevState: any) => ({ ...prevState, description: e })),
      width: CSS_COL_6,
    },
  ];

  /**
   * This loops through the user object fields array to create the fields elements for
   * display
   */
  let userFields = userFormFields.map((userObjectField: any) => {
    return getFormFieldComponent(userObjectField);
  });

  /**
   * This clears the hint messages
   */
  const clearHints = () => {
    userFormFields.forEach((formField: any) => {
      if (formField.isValidHint) {
        formField.setHint(null);
      }
    });
  };

  /**
   * This validates the form fields that have isValidHint attributes and sets their corresponding hints if the field validation
   * fails
   * @returns boolean
   */
  const validateForm = () => {
    clearHints();
    let isFormValid: boolean = true;

    userFormFields.forEach((formField: any) => {
      if (
        formField.setHint &&
        (formField.value === null || formField.value === "" || formField.value === undefined)
      ) {
        isFormValid = false;
        formField.setHint(formatString(MISSING_FORM_INPUT_MESSAGE, formField.label));
      }
    });

    return isFormValid;
  };

  /**
   * This submits a save user request to the backoffice
   */
  const saveUser = () => {
    let payload: any = record;
    let accountProfileFormData: FormData = new FormData();

    if (isNotEmpty(payload?.image)) {
      accountProfileFormData.append("file", payload?.image);
      payload.image = null;
    }
    payload.startDate = formatJSDate(payload.startDate);
    payload.endDate = formatJSDate(payload.endDate);
    accountProfileFormData.append(
      "dto",
      new Blob([JSON.stringify(payload)], { type: "application/json" })
    );
    if (validateForm()) {
      setIsSaving(true);
      new BaseApiServiceImpl("/api/v1/adverts")
        .postMultipartWithJsonResponse(accountProfileFormData)
        .then(async (response) => {
          setIsSaving(false);
          clearForm();
          MessageUtils.showSuccessMessage(
            props?.messageRef,
            labels.LABEL_RECORD_SAVED_SUCCESSFULLY
          );
          closeDialog();
          props?.reloadFn();
        })
        .catch((error) => {
          setIsSaving(false);
          MessageUtils.showErrorMessage(message, error.message);
        });
    }
  };

  /**
   * This closes the dialog
   */
  const closeDialog = () => {
    props.toggle();
  };

  /**
   * This is the footer of the modal dialog
   */
  const userDetailsDialogFooter = (
    <>
      <Button
        label={labels.LABEL_CANCEL}
        icon={PrimeIcons.TIMES}
        className="p-button-text"
        onClick={closeDialog}
      />
      <Button
        label={labels.LABEL_SAVE}
        icon={PrimeIcons.SAVE}
        className="p-button-secondary"
        onClick={saveUser}
        loading={isSaving}
      />
    </>
  );

  return (
    <Dialog
      visible={props.isOpen}
      header={"Advert Form"}
      footer={userDetailsDialogFooter}
      modal
      className="p-fluid"
      onHide={closeDialog}
      style={{ width: "50vw" }}
    >
      <Messages ref={message} />
      <div className="grid">
        <div className="col-12">
          <Messages ref={message} style={{ width: "100%" }} />
        </div>
        {userFields}
      </div>
    </Dialog>
  );
};

export default AdvertFormDialogView;
