import React, { useState, useEffect, useRef, ReactNode } from "react";
import { Messages } from "primereact/messages";

import { useParams } from "react-router-dom";
import * as labels from "../app_utils/constants/Labels";
import { BaseApiServiceImpl } from "../app_utils/api/BaseApiServiceImpl";
import { MessageUtils } from "../app_utils/utils/MessageUtils";
import { generalStatusBodyTemplate, toReadableDate } from "../app_utils/utils/Utils";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { MAXIMUM_RECORDS_PER_PAGE } from "../app_utils/constants/Constants";
import { Paginator } from "primereact/paginator";
import { paginatorTemplate } from "../app_utils/components/PaginatorTemplate";
interface PropType {
  children?: ReactNode;
  messageRef?: any;
  user: any;
  reloadFn?: any;
  isOpen?: boolean;
}
const AddressesTab = (props: PropType) => {
  let urlParams = useParams();
  let id = JSON.parse(JSON.stringify(urlParams)).id;
  const [records, setRecords] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [first, setFirst] = useState<number>(0);
  const [limit, setLimit] = useState<number>(MAXIMUM_RECORDS_PER_PAGE);
  const [totalItems, setTotalItems] = useState<number>(0);

  let offset = 0;
  const message = useRef<any>();

  /**
   * This fetches counties from the back office using the search parameters
   */
  const fetchRecordsFromServer = () => {
    setIsLoading(true);

    new BaseApiServiceImpl("/api/v1/addresses")
      .getRequestWithJsonResponse({ userId: id, offset: 0, limit: MAXIMUM_RECORDS_PER_PAGE })
      .then(async (response) => {
        setIsLoading(false);
        setRecords(response?.records);
      })
      .catch((error) => {
        setIsLoading(false);
        MessageUtils.showErrorMessage(message, error.message);
      });
  };

  /**
   * This hook is called everytime the page is loaded
   */
  useEffect(() => {
    fetchRecordsFromServer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * The record status row template
   * @param rowData
   * @returns
   */
  const statusBodyTemplate = (rowData: any) => {
    return generalStatusBodyTemplate(rowData?.recordStatus);
  };

  /**
   * The date  row template
   * @param rowData
   * @returns
   */
  const dateTemplate = (rowData: any) => {
    return <>{toReadableDate(rowData.chairpersonDateElected)}</>;
  };

  /**
   * The row index template
   * @param rowData
   * @param props
   * @returns
   */
  const rowIndexTemplate = (rowData: any, props: any) => {
    return (
      <React.Fragment>
        <span>{first + (props.rowIndex + 1)}</span>
      </React.Fragment>
    );
  };
  const countTemplate = (rowData: any) => {
    return <React.Fragment>0</React.Fragment>;
  };
  /**
   * This is called when a paginator link is clicked
   * @param e
   */
  const onPageChange = (e: any) => {
    offset = e.page * MAXIMUM_RECORDS_PER_PAGE;
    setFirst(e.first);
    setLimit(MAXIMUM_RECORDS_PER_PAGE);

    fetchRecordsFromServer();
  };

  return (
    <div className="grid">
      <div className="col-12">
        <Messages ref={message} style={{ width: "100%" }} />

        <DataTable
          value={records}
          paginator={false}
          className="datatable-responsive"
          paginatorPosition="both"
          emptyMessage="No record found."
          loading={isLoading}
        >
          <Column
            field="Index"
            header="#"
            style={{ width: "70px" }}
            body={rowIndexTemplate}
          ></Column>
          <Column field="title" header={"Title"}></Column>
          <Column field="type" header={"Type"}></Column>
          <Column field="description" header={"Description"}></Column>
          <Column field="startDate" header={"Start Date"}></Column>
          <Column field="endDate" header={"End Date"}></Column>
          <Column field="featured" header={"Featured ?"}></Column>

          <Column header={labels.LABEL_STATUS} body={statusBodyTemplate}></Column>
        </DataTable>
        <Paginator
          first={first}
          rows={MAXIMUM_RECORDS_PER_PAGE}
          totalRecords={totalItems}
          alwaysShow={true}
          onPageChange={onPageChange}
          template={paginatorTemplate}
        />
      </div>
    </div>
  );
};

export default AddressesTab;
