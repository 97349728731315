export const BACK_OFFICE_SECURITY_ROLES_ROUTE_PATH = "/backoffice/roles";
export const BACK_OFFICE_ROLE_PERMISSIONS_ROUTE_PATH = "/backoffice/roles/permissions";
export const BACK_OFFICE_CREATE_ROLE_ROUTE_PATH = "/backoffice/roles/create";
export const BACK_OFFICE_SECURITY_USERS_ROUTE_PATH = "/backoffice/users";
export const BACK_OFFICE_USER_ROLES_ROUTE_PATH = "/backoffice/users/:userId/roles";
export const HOME_ROUTE_PATH = "/home";
export const LOGIN_ROUTE_PATH = "/login";
export const REGISTER_ORGANISATION_ROUTE_PATH = "/register";
export const LANDING_ROUTE_PATH = "/";
export const MEMBERS_ROUTE_PATH = "/members";

export const MEMBER_DETAILS_ROUTE_PATH = "/members/details/:id";
export const ADVERTS_ROUTE_PATH = "/adverts";
export const GROUPS_ROUTE_PATH = "/circles";

export const CURRENCIES_ROUTE_PATH = "/currencies";
export const LOCATION_TRACKING_ROUTE_PATH = "/location-history";
export const SOS_CALLS_ROUTE_PATH = "/sos-calls";
export const TRANSLATION_LOGS_ROUTE_PATH = "/translation-logs";

export const GROUP_PAYMENTS_ROUTE_PATH = "/circle-payments";
export const USERS_ROUTE_PATH = "/users";
export const ORGANISATIONS_ROUTE_PATH = "/organisations";
export const PUSH_NOTIFICATIONS_ROUTE_PATH = "/notifications";
export const SETTINGS_ROUTE_PATH = "/system-settings";
export const LOOKUPSROUTE_PATH = "/lookups";

export const PRODUCT_FORM_ROUTE_PATH = "/product/form/:id";
export const PRODUCTS_VIEW_ROUTE_PATH = "/products";
export const PRODUCT_CATEGORIES_VIEW_ROUTE_PATH = "/product-categories";
export const ORDERS_VIEW_ROUTE_PATH = "/orders";

export const AGGREGATOR_TRANSACTIONS_VIEW_ROUTE_PATH = "/aggregator-transactions";
export const ORDER_DETAILS_VIEW_ROUTE_PATH = "/orders/details/:id";
export const WALLET_TRANSACTIONS_VIEW_ROUTE_PATH = "/wallet-transactions";
