import { Dialog } from "primereact/dialog";
import { Messages } from "primereact/messages";
import { ReactNode, useEffect, useRef, useState } from "react";
import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { FormFieldTypes } from "../app_utils/constants/FormFieldTypes";
import {
  getFormFieldComponent,
  validateEmptyField,
} from "../app_utils/components/FormFieldTemplates";
import { formatString, memberLabelTemplate } from "../app_utils/utils/Utils";
import { MISSING_FORM_INPUT_MESSAGE } from "../app_utils/constants/ErrorMessages";
import {
  CSS_COL_6,
  MAXIMUM_RECORDS_PER_PAGE,
  MINIMUM_FILTER_QUERY_LENGTH,
} from "../app_utils/constants/Constants";
import { BaseApiServiceImpl } from "../app_utils/api/BaseApiServiceImpl";
import { MessageUtils } from "../app_utils/utils/MessageUtils";
import * as labels from "../app_utils/constants/Labels";

interface ModalType {
  children?: ReactNode;
  messageRef?: any;
  memberObject: any;
  reloadFn: any;
  isOpen: boolean;
  toggle: () => void;
}

const GroupsFormDialogView = (props: ModalType) => {
  const [id, setId] = useState(null);
  const [name, setName] = useState("");
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [bank, setBank] = useState(null);
  const [leadMother, setLeadMother] = useState(null);
  const [secretary, setSecretary] = useState(null);
  const [treasurer, setTreasurer] = useState(null);
  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [groupComment, setGroupComment] = useState("");
  const [members, setMembers] = useState<any>([]);
  const [banks, setBanks] = useState<any>([]);

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const message = useRef<any>();

  /**
   * This hook is called when the object is changed. This happens
   * in the parent view when the is changed
   */
  useEffect(() => {
    populateForm(props?.memberObject);
    fetchMembersFromServer(props?.memberObject?.memberName);
    fetchBanksFromServer();
  }, [props?.memberObject]);

  const fetchBanksFromServer = () => {
    new BaseApiServiceImpl("/api/v1/lookups/lookup-values")
      .getRequestWithJsonResponse({
        offset: 0,
        limit: 200,
        commaSeparatedTypeIds: "5",
      })
      .then(async (response) => {
        const result = response?.records;
        setBanks(result);
      })
      .catch((error) => {
        MessageUtils.showErrorMessage(message, error.message);
      });
  };
  const fetchMembersFromServer = (searchTerm: any) => {
    let searchParams: any = { offset: 0, limit: MAXIMUM_RECORDS_PER_PAGE };
    if (searchTerm != null && searchTerm != undefined) {
      searchParams.searchTerm = searchTerm;
    }

    new BaseApiServiceImpl("/api/v1/members")
      .getRequestWithJsonResponse(searchParams)
      .then(async (response) => {
        setMembers(response?.records);
      })
      .catch((error) => {
        MessageUtils.showErrorMessage(message, error.message);
      });
  };
  const onMemberFilter = (filterEvent: any) => {
    const filterTerm = filterEvent.filter;
    if (
      filterTerm.length >= MINIMUM_FILTER_QUERY_LENGTH ||
      filterTerm.length % 2 === 0
    ) {
      fetchMembersFromServer(filterTerm);
    }
  };

  /**
   * This clears the form by setting form values to null
   */
  const clearForm = () => {
    populateForm(null);
  };

  const populateForm = (dataObject: any) => {
    setId(dataObject?.id);
    setAccountName(dataObject?.accountName);
    setAccountNumber(dataObject?.accountNumber);
    setBank(dataObject?.bank?.id);
    setName(dataObject?.name);
    setRegistrationNumber(dataObject?.registrationNumber);
    setGroupComment(dataObject?.groupComment);
    setLeadMother(dataObject?.leadMother?.id);
    setTreasurer(dataObject?.treasurer?.id);
    setSecretary(dataObject?.secretary?.id);
  };

  /**
   * This is a list of member form fields
   */
  let memberFormFields: any = [
    {
      type: FormFieldTypes.TEXT.toString(),
      label: "Name",
      value: name,
      onChange: setName,
      width: CSS_COL_6,
    },
    {
      type: FormFieldTypes.TEXT.toString(),
      label: "registrationNumber",
      value: registrationNumber,
      onChange: setRegistrationNumber,
      width: CSS_COL_6,
    },
    {
      type: FormFieldTypes.DROPDOWN.toString(),
      label: "Bank",
      value: bank,
      onChange: setBank,
      options: banks,
      optionValue: "id",
      optionLabel: "name",
      width: CSS_COL_6,
    },
    {
      type: FormFieldTypes.TEXT.toString(),
      label: "Bank Account Number",
      value: accountNumber,
      onChange: setAccountNumber,
      width: CSS_COL_6,
    },
    {
      type: FormFieldTypes.TEXT.toString(),
      label: "Bank Account Name",
      value: accountName,
      onChange: setAccountName,
      width: CSS_COL_6,
    },
    {
      type: FormFieldTypes.DROPDOWN.toString(),
      label: "Circle Leader (Lead Mother)",
      value: leadMother,
      onChange: setLeadMother,
      options: members,
      optionValue: "id",
      optionLabel: "fullName",
      onFilter: onMemberFilter,
      itemTemplate: memberLabelTemplate,
      width: CSS_COL_6,
    },

    {
      type: FormFieldTypes.DROPDOWN.toString(),
      label: "Secretary",
      value: secretary,
      onChange: setSecretary,
      options: members,
      optionValue: "id",
      optionLabel: "fullName",
      onFilter: onMemberFilter,
      itemTemplate: memberLabelTemplate,
      width: CSS_COL_6,
    },

    {
      type: FormFieldTypes.DROPDOWN.toString(),
      label: "Treasurer",
      value: treasurer,
      onChange: setTreasurer,
      options: members,
      optionValue: "id",
      optionLabel: "fullName",
      onFilter: onMemberFilter,
      itemTemplate: memberLabelTemplate,
      width: CSS_COL_6,
    },

    {
      type: FormFieldTypes.TEXTAREA.toString(),
      label: "Comment",
      value: groupComment,
      onChange: setGroupComment,
      width: CSS_COL_6,
    },
  ];

  /**
   * This loops through the member object fields array to create the fields elements for
   * display
   */
  let memberFields = memberFormFields.map((memberObjectField: any) => {
    return getFormFieldComponent(memberObjectField);
  });

  /**
   * This clears the hint messages
   */
  const clearHints = () => {
    memberFormFields.forEach((formField: any) => {
      if (formField.isValidHint) {
        formField.setHint(null);
      }
    });
  };

  /**
   * This validates the form fields that have isValidHint attributes and sets their corresponding hints if the field validation
   * fails
   * @returns boolean
   */
  const validateForm = () => {
    clearHints();
    let isFormValid: boolean = true;

    memberFormFields.forEach((formField: any) => {
      if (
        formField.setHint &&
        (formField.value === null ||
          formField.value === "" ||
          formField.value === undefined)
      ) {
        isFormValid = false;
        formField.setHint(
          formatString(MISSING_FORM_INPUT_MESSAGE, formField.label)
        );
      }
    });

    return isFormValid;
  };

  /**
   * This submits a save member request to the backoffice
   */
  const saveMember = () => {
    let memberData: any = {
      id: id,
      name,
      accountName,
      setAccountNumber,
      groupComment,
      leadMother,
      secretary,
      treasurer,
      bank,
      registrationNumber,
    };

    if (validateForm()) {
      setIsSaving(true);
      new BaseApiServiceImpl("/api/v1/circles")
        .postRequestWithJsonResponse(memberData)
        .then(async (response) => {
          setIsSaving(false);
          clearForm();
          MessageUtils.showSuccessMessage(
            props?.messageRef,
            labels.LABEL_RECORD_SAVED_SUCCESSFULLY
          );
          closeDialog();
          props?.reloadFn();
        })
        .catch((error) => {
          setIsSaving(false);
          MessageUtils.showErrorMessage(message, error.message);
        });
    }
  };

  /**
   * This closes the dialog
   */
  const closeDialog = () => {
    props.toggle();
  };

  /**
   * This is the footer of the modal dialog
   */
  const memberDetailsDialogFooter = (
    <>
      <Button
        label={labels.LABEL_CANCEL}
        icon={PrimeIcons.TIMES}
        className="p-button-text"
        onClick={closeDialog}
      />
      <Button
        label={labels.LABEL_SAVE}
        icon={PrimeIcons.SAVE}
        className="p-button-secondary"
        onClick={saveMember}
        loading={isSaving}
      />
    </>
  );

  return (
    <Dialog
      minX={200}
      visible={props.isOpen}
      header={"Add circle form"}
      footer={memberDetailsDialogFooter}
      modal
      className="p-fluid"
      onHide={closeDialog}
      style={{ width: "50vw" }}
    >
      <Messages ref={message} />
      <div className="grid">
        <div className="col-12">
          <Messages ref={message} style={{ width: "100%" }} />
        </div>
        {memberFields}
      </div>
    </Dialog>
  );
};

export default GroupsFormDialogView;
