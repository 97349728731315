import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useState, useEffect, useRef } from "react";
import { Messages } from "primereact/messages";
import { Paginator } from "primereact/paginator";
import * as constants from "../app_utils/constants/Constants";
import { PrimeIcons } from "primereact/api";
import { BaseApiServiceImpl } from "../app_utils/api/BaseApiServiceImpl";
import { MessageUtils } from "../app_utils/utils/MessageUtils";
import {
  generalStatusBodyTemplate,
  getCurrentDateISOFormat,
  getDaysFromCurrentISOFormat,
  getFirstDateOfMonth,
  isNotEmpty,
  replaceWithUnderscore,
  toReadableDate,
} from "../app_utils/utils/Utils";

import { Toast } from "primereact/toast";
import { GMap } from "primereact/gmap";
import { getFilterComponent } from "../app_utils/components/Filters";
import { paginatorTemplate } from "../app_utils/components/PaginatorTemplate";
import { useParams } from "react-router-dom";
import { loadGoogleMaps, removeGoogleMaps } from "../app_utils/utils/GoogleMapsUtils";
const LocationHistoriesView = (props: any) => {
  let urlParams = useParams();
  let id = JSON.parse(JSON.stringify(urlParams)).id;
  const [records, setRecords] = useState<any>(null);
  const [defaultCenter, setDefaultCenter] = useState<any>({ lat: 1.31873, lng: 32.511981 });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [first, setFirst] = useState<number>(0);
  const [limit, setLimit] = useState<number>(200);

  const toast = useRef<any>(null);
  let offset = 0;

  const message = useRef<any>();

  /**
   * This gets the parameters to submit in the GET request to back office
   * @returns
   */
  const getQueryParameters = () => {
    let searchParameters: any = { offset: offset, limit: limit, userId: id };
    if (startDate !== null) searchParameters.startDate = startDate;
    if (endDate !== null) searchParameters.endDate = endDate;

    return searchParameters;
  };
  /**
   * This loads the default map centered in Nakasongola, Uganda
   */
  const defaultMapOptions = {
    center: defaultCenter,
    zoom: 12,
  };
  const [googleMapsReady, setGoogleMapsReady] = useState<boolean | null>(false);
  const [overlays, setOverlays] = useState<any>([]);

  const googleMapsAPIKey = "AIzaSyCFn4BlSSVjsbBup_Jt7l2xFwvdydk6kxs";
  /**
   * This fetches counties from the back office using the search parameters
   */
  const fetchRecordsFromServer = (date1: any = null, date2: any = null) => {
    setIsLoading(true);
    let searchParameters: any = { offset: 0, limit: 0, userId: id };
    if (isNotEmpty(startDate)) searchParameters.startDate = startDate;
    if (isNotEmpty(endDate)) searchParameters.endDate = endDate;
    if (isNotEmpty(date1)) searchParameters.startDate = date1;
    if (isNotEmpty(date2)) searchParameters.endDate = date2;
    new BaseApiServiceImpl("/api/v1/location-history")
      .getRequestWithJsonResponse(searchParameters)
      .then(async (response) => {
        setIsLoading(false);
        setRecords(response?.records);
        if (response.totalItems > 0) {
          loadMarkersOnMap(response?.records);
        }
        setTotalItems(response?.totalItems);
      })
      .catch((error) => {
        setIsLoading(false);
        MessageUtils.showErrorMessage(message, error.message);
      });
  };

  /**
   * This hook is called everytime the page is loaded
   */
  useEffect(() => {
    fetchRecordsFromServer(getCurrentDateISOFormat(), null);
    loadGoogleMaps(() => {
      setGoogleMapsReady(true);
    }, googleMapsAPIKey);

    return () => {
      removeGoogleMaps();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * This is used everytime the user presses the enter button on any form input of a filter
   */
  const onSubmitFilter = () => {
    setStartDate(null);
    setEndDate(null);
    fetchRecordsFromServer();
  };

  /**
   * This is used to clear all filters and resubmit the get request to the back office
   */
  const resetFilters = () => {
    setStartDate(null);
    setEndDate(null);
    fetchRecordsFromServer();
  };

  const generateIcon = (key: number) => {
    const icon = {
      url:
        key == 0
          ? "https://cdn-icons-png.flaticon.com/256/9183/9183498.png"
          : "https://cdn-icons-png.flaticon.com/128/10024/10024140.png", // url
      scaledSize: new google.maps.Size(50, 50), // scaled size
      origin: new google.maps.Point(0, 0), // origin
      anchor: new google.maps.Point(0, 0), // anchor
    };
    return icon;
  };
  //const {Marker} = google.maps.importLibrary("marker")
  const loadMarkersOnMap = (data: any) => {
    let positions: any = [];
    var line: any = {};
    var currentLocation: any = {};
    if (googleMapsReady == true) {
      let polygonPaths: any = [];
      data.map((d: any, key: number) => {
        const latitude = d.latitude;
        const longitude = d.longitude;
        if (key == 0) {
          setDefaultCenter({ lat: latitude, lng: longitude });
          currentLocation = new google.maps.Marker({
            position: { lat: latitude, lng: longitude },
            icon: generateIcon(key),
            title:
              d.createdByFullName +
              " | " +
              toReadableDate(d.dateCreated) +
              " | Speed :" +
              d.velocity +
              " | Elevation :" +
              d.elevation,
          });
        }
        polygonPaths.push({ lat: latitude, lng: longitude });
      });
      line = new google.maps.Polyline({
        path: polygonPaths,
        geodesic: true,
        strokeColor: "#0f03fc",
        strokeOpacity: 0.5,
        strokeWeight: 6,
      });
      positions.push(currentLocation);
      setOverlays([line, ...positions]);
    }
  };

  /**
   * The template for the filter buttons
   */
  const filterButtonsTemplate = (
    <>
      <div className="col-12 p-fluid" key="filterBtns">
        <Button
          icon={constants.ICON_SEARCH}
          className={"col-6 "}
          onClick={onSubmitFilter}
          loading={isLoading}
          label="Search"
        />
        <Button
          label="Clear"
          icon={PrimeIcons.FILTER_SLASH}
          className={"col-6 mt-2 p-button-warning"}
          onClick={resetFilters}
          loading={isLoading}
        />
      </div>
    </>
  );

  /**
   * This is a list of filters to display in the filter section
   */
  const filterDetails = [
    {
      type: "date",
      value: startDate,
      onChangeFn: setStartDate,
      id: "startDate",
      label: "Start Date",
      colWidth: constants.CSS_COL_12,
      onkeydownFn: onSubmitFilter,
    },
    {
      type: "date",
      value: endDate,
      onChangeFn: setEndDate,
      id: "endDate",
      label: "End Date",
      colWidth: constants.CSS_COL_12,
      onkeydownFn: onSubmitFilter,
    },
  ];
  const onMapReady = (event: any) => {
    fetchRecordsFromServer();
    setOverlays([]);
  };
  const onOverlayClick = (event: any) => {
    let isMarker = event.overlay.getTitle !== undefined;

    if (isMarker) {
      let title = event.overlay.getTitle();
      event.map.setCenter(event.overlay.getPosition());
      toast.current.show({ severity: "info", summary: "Marker Selected", detail: title });
    } else {
      toast.current.show({ severity: "info", summary: "Shape Selected", detail: "" });
    }
  };
  /**
   * This loops through the filter Details array and returns the components
   * to render in the detailed form filter dialog.
   */
  const dynamicFilterDetails = filterDetails.map((filter: any) => {
    let component = getFilterComponent(filter);
    return (
      <div className={filter.colWidth} key={replaceWithUnderscore(filter.id)}>
        <label htmlFor={filter.id}>{filter.label}</label>
        {component}
      </div>
    );
  });

  return (
    <div className="grid mt-2">
      <Messages ref={message} style={{ width: "100%" }} />
      <div className="col-2">
        <span className="p-buttonset">
          <Button
            label="Today"
            className="w-full"
            onClick={() => {
              fetchRecordsFromServer(getCurrentDateISOFormat(), null);
            }}
          />
          <Button
            label="Yesterday"
            className="w-full"
            onClick={() => {
              fetchRecordsFromServer(getDaysFromCurrentISOFormat(-1), getCurrentDateISOFormat());
            }}
          />
          <Button
            label="This Week"
            className="w-full"
            onClick={() => {
              fetchRecordsFromServer(getDaysFromCurrentISOFormat(-7), getCurrentDateISOFormat());
            }}
          />
          <Button
            label="This Month"
            className="w-full"
            onClick={() => {
              fetchRecordsFromServer(getFirstDateOfMonth(), null);
            }}
          />

          {dynamicFilterDetails}
          {filterButtonsTemplate}
        </span>
      </div>
      <div className="col-10">
        <div className="card">
          <Toast ref={toast}></Toast>
          <div className="my-2">
            Total Records : <span>{totalItems}</span>
          </div>
          {googleMapsReady && (
            <div>
              <GMap
                overlays={overlays}
                options={defaultMapOptions}
                style={{ width: "100%", minHeight: "400px" }}
                onMapReady={onMapReady}
                onOverlayClick={onOverlayClick}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LocationHistoriesView;
