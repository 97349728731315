import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useState, useEffect, useRef } from "react";
import { Messages } from "primereact/messages";
import { Paginator } from "primereact/paginator";
import { useHistory } from "react-router";
import { Panel } from "primereact/panel";
import { BreadCrumb } from "primereact/breadcrumb";
import * as constants from "../app_utils/constants/Constants";
import { generatePath } from "react-router-dom";
import * as labels from "../app_utils/constants/Labels";
import useShowModalDialog from "../app_utils/components/ShowModalHook";
import {
  HOME_ROUTE_PATH,
  MEMBER_DETAILS_ROUTE_PATH,
} from "../app_utils/route_paths/resolver/PageRoutes";
import { PrimeIcons } from "primereact/api";
import { BaseApiServiceImpl } from "../app_utils/api/BaseApiServiceImpl";
import { MessageUtils } from "../app_utils/utils/MessageUtils";
import {
  exportDataToCsv,
  generalStatusBodyTemplate,
  getCurrentDateISOFormat,
  isNotEmpty,
  replaceWithUnderscore,
  sanitizeValue,
  toReadableDate,
} from "../app_utils/utils/Utils";
import { getFilterComponent } from "../app_utils/components/Filters";
import { paginatorTemplate } from "../app_utils/components/PaginatorTemplate";
import { filtersHeadertemplate } from "../app_utils/components/FiltersPanelHeader";
import UploadFormDialog from "./UploadFormDialog";

const MembersView = () => {
  const [records, setRecords] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchTermFilter, setSearchTermFilter] = useState<string | null>(null);
  const [recordStatusFilter, setRecordStatusFilter] = useState<string | null>(null);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [first, setFirst] = useState<number>(0);
  const [limit, setLimit] = useState<number>(constants.MAXIMUM_RECORDS_PER_PAGE);
  const [openUploadDialog, setOpenUploadDialog] = useState<boolean>(false);
  const [referrers, setReferrers] = useState<any>([]);
  const [countries, setCountries] = useState<any>([]);
  const [languages, setLanguages] = useState<any>([]);
  const [referrer, setReferrer] = useState<any>(null);
  const [country, setCountry] = useState<any>(null);
  const [language, setLanguage] = useState<any>(null);
  const [selectedMember, setSelectedMember] = useState<any>(null);
  const [territories, setTerritories] = useState<any>(null);
  const [territoryFilter, setTerritoryFilter] = useState<any>(null);

  let offset = 0;
  let record = null;
  const message = useRef<any>();
  const history = useHistory();

  const { openDialog, toggleOpenDialog } = useShowModalDialog();

  /**
   * These are the bread crumbs that serve as the title of the page
   */
  const breadcrumbHome = {
    icon: "pi pi-home",
    command: () => {
      history.push(HOME_ROUTE_PATH);
    },
  };

  const breadcrumbItems = [
    {
      label: `App Users`,
      icon: PrimeIcons.FLAG,
    },
  ];

  /**
   * This gets the parameters to submit in the GET request to back office
   * @returns
   */
  const getQueryParameters = () => {
    let searchParameters: any = { offset: offset, limit: limit };
    if (searchTermFilter !== null) searchParameters.searchTerm = searchTermFilter;
    if (recordStatusFilter !== null) searchParameters.recordStatus = recordStatusFilter;
    if (isNotEmpty(language)) searchParameters.language = language;
    if (isNotEmpty(referrer)) searchParameters.referrer = referrer;
    if (isNotEmpty(country)) searchParameters.country = country;
    return searchParameters;
  };

  /**
   * This fetches counties from the back office using the search parameters
   */
  const fetchRecordsFromServer = () => {
    setIsLoading(true);
    let searchParameters: any = getQueryParameters();

    new BaseApiServiceImpl("/api/v1/users/app-users")
      .getRequestWithJsonResponse(searchParameters)
      .then(async (response) => {
        setIsLoading(false);
        setRecords(response?.records);
        setTotalItems(response?.totalItems);
      })
      .catch((error) => {
        setIsLoading(false);
        MessageUtils.showErrorMessage(message, error.message);
      });
  };
  const fetchLanguagesFromServer = () => {
    new BaseApiServiceImpl("/api/v1/lookups/languages")
      .getRequestWithJsonResponse({})
      .then(async (response) => {
        setLanguages(response?.records);
      })
      .catch((error) => {
        MessageUtils.showErrorMessage(message, error.message);
      });
  };
  const fetchReferrersFromServer = () => {
    new BaseApiServiceImpl("/api/v1/lookups/app-referrers")
      .getRequestWithJsonResponse({})
      .then(async (response) => {
        setReferrers(response?.records);
      })
      .catch((error) => {
        MessageUtils.showErrorMessage(message, error.message);
      });
  };
  const fetchCountriesFromServer = () => {
    new BaseApiServiceImpl("/api/v1/lookups/countries")
      .getRequestWithJsonResponse({ offset: 0, limit: 0 })
      .then(async (response) => {
        setCountries(response?.records);
      })
      .catch((error) => {
        MessageUtils.showErrorMessage(message, error.message);
      });
  };

  /**
   * This hook is called everytime the page is loaded
   */
  useEffect(() => {
    fetchRecordsFromServer();
    fetchCountriesFromServer();
    fetchLanguagesFromServer();
    fetchReferrersFromServer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * This is used everytime the user presses the enter button on any form input of a filter
   */
  const onSubmitFilter = () => {
    setSearchTermFilter(searchTermFilter);
    setRecordStatusFilter(recordStatusFilter);
    setTerritoryFilter(territoryFilter);
    fetchRecordsFromServer();
  };

  /**
   * This is used to clear all filters and resubmit the get request to the back office
   */
  const resetFilters = () => {
    setSearchTermFilter("");
    setRecordStatusFilter(null);
    setTerritoryFilter(null);
    fetchRecordsFromServer();
  };

  /**
   * This opens the edit territory dialog form by toggling the open dialog variable
   */
  const openEditFormDialog = (rowData: any) => {
    setSelectedMember(rowData);
    record = rowData;
    toggleOpenDialog();
  };

  /**
   * This opens the new territory dialog form by toggling the open dialog variable
   * and setting the selected territory to null
   */
  const openNewFormDialog = () => {
    setSelectedMember(null);
    toggleOpenDialog();
  };

  /**
   * The row index template
   * @param rowData
   * @param props
   * @returns
   */
  const rowIndexTemplate = (rowData: any, props: any) => {
    return (
      <React.Fragment>
        <span>{first + (props.rowIndex + 1)}</span>
      </React.Fragment>
    );
  };

  /**
   * This is called when a paginator link is clicked
   * @param e
   */
  const onPageChange = (e: any) => {
    offset = e.page * constants.MAXIMUM_RECORDS_PER_PAGE;
    setFirst(e.first);
    setLimit(constants.MAXIMUM_RECORDS_PER_PAGE);

    fetchRecordsFromServer();
  };

  /**
   * The action body template
   * @param rowData
   * @returns
   */
  const actionBodyTemplate = (rowData: any) => {
    return (
      <div className="actions">
        <Button
          icon={PrimeIcons.EYE}
          className="p-button-sm p-button-primary "
          onClick={() => {
            history.push(generatePath(MEMBER_DETAILS_ROUTE_PATH, { id: rowData.id }));
          }}
        />
      </div>
    );
  };

  /**
   * The record status row template
   * @param rowData
   * @returns
   */
  const statusBodyTemplate = (rowData: any) => {
    return generalStatusBodyTemplate(rowData?.recordStatus);
  };

  /**
   * The date  row template
   * @param rowData
   * @returns
   */
  const dateTemplate = (rowData: any) => {
    return <>{toReadableDate(rowData.member.dateOfBirth, true)}</>;
  };

  const referralTemplate = (rowData: any) => {
    return <>{sanitizeValue(rowData?.referredBy?.value)}</>;
  };
  const locationTemplate = (rowData: any) => {
    return (
      <>{`${sanitizeValue(rowData.member.village?.value)} , ${sanitizeValue(
        rowData.member.parish?.value
      )}`}</>
    );
  };
  const nameTemplate = (rowData: any) => {
    return <>{`${rowData?.firstName} ${rowData?.lastName}`}</>;
  };

  const dateCreatedTemplate = (rowData: any) => {
    return toReadableDate(rowData?.dateCreated, false);
  };

  const lastSeenTemplate = (rowData: any) => {
    return toReadableDate(rowData?.lastSeenAt, false);
  };

  /**
   * The template for the filter buttons
   */
  const filterButtonsTemplate = (
    <>
      <div className="col-6  md:col-2 p-fluid" key="filterBtns">
        <Button
          icon={constants.ICON_SEARCH}
          className={constants.CSS_FILTER_SUBMIT_BUTTON}
          onClick={onSubmitFilter}
          loading={isLoading}
        />
        <Button
          icon={constants.ICON_REFRESH}
          className={constants.CSS_FILTER_RESET_BUTTON}
          onClick={resetFilters}
          loading={isLoading}
        />
      </div>
    </>
  );
  const doExport = () => {
    try {
      let searchParameters: any = { offset: offset, limit: 500 };

      setIsLoading(true);
      new BaseApiServiceImpl("/api/v1/users/app-users")
        .getRequestWithJsonResponse(searchParameters)
        .then(async (response) => {
          exportDataToCsv(response.records, "MyGuardianUsers_" + getCurrentDateISOFormat(), () =>
            setIsLoading(false)
          );
        });
    } catch (error: any) {
      setIsLoading(false);
      MessageUtils.showErrorMessage(message, error.message);
    }
  };
  /**
   * This is a list of filters to display in the filter section
   */
  const filterDetails = [
    {
      type: "text",
      value: searchTermFilter,
      onChangeFn: setSearchTermFilter,
      id: "searchTermFilter",
      label: labels.LABEL_SEARCH_TERM,
      colWidth: constants.CSS_FILTER_SEARCH_INPUT_DIV,
      onkeydownFn: onSubmitFilter,
    },
    {
      type: "dropdown",
      value: language,
      onChangeFn: setLanguage,
      id: "Language",
      label: "Language",
      options: languages,
      optionLabel: "value",
      optionValue: "id",
      colWidth: constants.CSS_FILTER_DEFAULT_DIV,
      onkeydownFn: onSubmitFilter,
    },
    {
      type: "dropdown",
      value: referrer,
      onChangeFn: setReferrer,
      id: "referrer",
      label: "Referrer",
      options: referrers,
      optionLabel: "value",
      optionValue: "id",
      colWidth: constants.CSS_FILTER_DEFAULT_DIV,
      onkeydownFn: onSubmitFilter,
    },
    {
      type: "dropdown",
      value: country,
      onChangeFn: setCountry,
      id: "Country",
      label: "Country",
      options: countries,
      optionLabel: "value",
      optionValue: "id",
      colWidth: constants.CSS_FILTER_DEFAULT_DIV,
      onkeydownFn: onSubmitFilter,
    },
  ];

  /**
   * This loops through the filter Details array and returns the components
   * to render in the detailed form filter dialog.
   */
  const dynamicFilterDetails = filterDetails.map((filter: any) => {
    let component = getFilterComponent(filter);
    return (
      <div className={filter.colWidth} key={replaceWithUnderscore(filter.id)}>
        <label htmlFor={filter.id}>{filter.label}</label>
        {component}
      </div>
    );
  });
  const toggleUploadDialog = () => {
    setOpenUploadDialog(!openUploadDialog);
  };
  return (
    <div className="grid mt-2">
      <div className="col-8 flex justify-content-start flex-wrap">
        <BreadCrumb home={breadcrumbHome} model={breadcrumbItems} />
      </div>
      <div className="col-4 flex justify-content-end flex-wrap">
        <Button
          icon={PrimeIcons.DOWNLOAD}
          className="p-button-primary mr-2 ml-2 "
          label="Export"
          onClick={doExport}
          loading={isLoading}
        />
      </div>
      <Messages ref={message} style={{ width: "100%" }} />
      <div className="col-12">
        <Panel headerTemplate={filtersHeadertemplate} toggleable>
          <div className="grid">
            {dynamicFilterDetails}
            {filterButtonsTemplate}
          </div>
        </Panel>
      </div>
      <div className="col-12">
        <div className="card">
          <DataTable
            responsiveLayout="stack"
            value={records}
            paginator={false}
            stripedRows
            size="normal"
            className="datatable-responsive"
            paginatorPosition="both"
            emptyMessage="No record found."
            loading={isLoading}
          >
            <Column
              field="Index"
              header="#"
              style={{ width: "70px" }}
              body={rowIndexTemplate}
            ></Column>
            <Column field="fullName" header={"Full Name"}></Column>
            <Column field="username" header={"Phone Number"}></Column>

            <Column body={referralTemplate} header={"Referrer"}></Column>

            <Column body={dateCreatedTemplate} header={"Date Created"}></Column>
            <Column body={lastSeenTemplate} header={"Last Seen"}></Column>
            <Column field="emailAddress" header={"Email Address"}></Column>
            <Column
              field="accountStatus"
              body={(rowData: any) => generalStatusBodyTemplate(rowData?.accountStatus)}
              header={"Status"}
            ></Column>
            <Column style={{ width: "70px" }} header="Actions" body={actionBodyTemplate}></Column>
          </DataTable>

          <Paginator
            first={first}
            rows={constants.MAXIMUM_RECORDS_PER_PAGE}
            totalRecords={totalItems}
            alwaysShow={true}
            onPageChange={onPageChange}
            template={paginatorTemplate}
          />
        </div>
      </div>

      {openUploadDialog && (
        <UploadFormDialog
          isOpen={openUploadDialog}
          toggleOpen={toggleUploadDialog}
          reloadFn={fetchRecordsFromServer}
        ></UploadFormDialog>
      )}
    </div>
  );
};

export default MembersView;
